import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const descriptors = ["but then I met you."];

const delays = [3000]; // delays for each message

export default function Sam3() {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    if (index < descriptors.length) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setIndex((prevIndex) => prevIndex + 1);
          setFade(true);
        }, 500); // 0.5 second fade out
      }, delays[index] + 500); // delay for current message + fade out time

      return () => clearInterval(interval);
    }
  }, [index]);

  return (
    <div className="font-sans font-bold flex items-center h-screen">
      <div className="text-9xl text-center m-auto">
        {index < descriptors.length ? (
          <span
            style={{ opacity: fade ? 1 : 0, transition: "opacity 0.5s" }}
            dangerouslySetInnerHTML={{ __html: descriptors[index] }}
          ></span>
        ) : (
          <Navigate to="/jadenlovessamanthamost" />
        )}
      </div>
    </div>
  );
}
