import confetti from "canvas-confetti";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Card() {
  const today = new Date();
  const targetDate = new Date("November 28, 2024");
  const timeDifference = targetDate - today;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    confetti({
      particleCount: 150,
      spread: 180,
    });
  }, []);

  return (
    <div>
      <Link to="/jadenlovessamanthamost">
        <button className="fixed top-0 left-0 p-4 hover:text-green-400">
          {"<"}
        </button>
      </Link>
      <div className="birthdayCard">
        <div className="cardFront">
          <h3 className="happy">Happy 18th Birthday Samantha!</h3>
          <div className="balloons">
            <div className="balloonOne" />
            <div className="balloonTwo" />
            <div className="balloonThree" />
            <div className="balloonFour" />
          </div>
        </div>
        <div className="cardInside">
          {/* <h3 className="back">Happy Birthday Samantha!</h3> */}
          <p>To: My honey bunny bear boo</p>
          <p>From: Your bubs</p>
          <p>Date: Sunday November 10, 2024</p>
          <p>
            <span>Dear Sam, </span>
            <br></br>
            Happy 18th birthday! I hope you have the most amazing day being just
            a bit older than you were yesterday! This isn't as much of a
            birthday card as it is an appreciation and love card, I couldn't
            help it. You are the light of my life and I don't know how I went 16
            years of my life without you. From all the times in math class being
            right next to you, and now being 864.27 kilometres away, my love and
            admiration for you has only grown. I love your silliness and
            nightime dilly-dallying with me. Your little dances are so adorable
            and I absolutely love your smile. The way your cheeks rise and your
            dimple shows with your little grin is the most beautiful and
            adorable thing I've ever seen. The way you climb over the couch in
            your basement and jump around when you get excited gets me excited.
            You are so cute when you realize I'm going to chase you around and
            you start running and jumping, and you look back and give me your
            perfect smile. I love the way you look at me, the way you talk to
            me, the way you hold me, the way you kiss me. I am so in love with
            you, Samantha, I just can't help it. I love your handwriting and
            your admiration for inks and pens. Your passion for stationery and
            art is so inspiring and I love seeing you create. I love your love
            for your family and friends. I love how much you care for your
            Grandpa and how much you care for your Grandma and Granny. I love
            your clothing style and mirror selfies of your outfit. I love the
            way you talk about your day and let me know what's going on in your
            life. The way you think about your homework problems and light up
            when you solve them makes me light up. Your interest in l'Hopital's
            rule and hatred for other math problems is so cute. I love you so
            much and want you to have the best rest always, so hopefully you can
            get that soon. I love how hardworking you are and how much you care.
            Ever since I met you, I've been so happy to just be in your
            presence.
          </p>
          <p>
            I still can't believe you said yes to going on a date with me. I
            don't know how you've put up with me for so long, but I'm so
            grateful you have. Being able to know you and love you will be the
            best thing that will ever happen in my life, and I'm so excited to
            get to know you for the rest of our lives. I am so excited to see
            you grow and change, be able to meet you at airports and train
            stations. And soon, I'll be able to meet you in our bed, in the
            mornings, in our kitchen, in our living room, in our backyard, in
            our car, in our future home. I'm excited to hug you from behind or
            lift you onto the counter and kiss you in our home. I'm so excited
            to build a family with you and be able to live my life knowing that
            you are my partner through it all. Calling you my girlfriend, my
            fiancée, my wife, my partner, my love, my honey bunny bear boo, is
            the most amazing thing ever. I loved watching all the movies with
            you, even if we couldn't get through all of them for one reason or
            another. I love being your comfort. I love sleeping next to you and
            waking up next to you, I look forward to more of that soon.
          </p>
          <p>
            Having your hand in mine is the best feeling in the world. In{" "}
            {daysLeft} days, I'll be able to hold your hand, kiss you, hug you,
            cuddle with you, and do{" "}
            <span
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              className="text-red-900"
            >
              fun activities with you :)
            </span>
          </p>
          <p className="small-text">
            {isHovering &&
              "Samantha, I miss your body on mine so bad. I miss having my hands all over you and kissing every bit of you. You're so hot. I can't wait to fuck you and taste you. I really want your mouth on my mouth (and other parts of me). I want you to rest well so that I can be the one to tire you out. Soon, I'll be able to shower with and fuck and cuddle and feel you."}
          </p>
          <p>
            I've said this before, but this time I'll write it out clearly: If
            there's only one thing that I know was the right decision in my
            life, it was you. The only thing I know for certain is that loving
            you was the best thing I will have ever accomplished. In this
            lifetime and any lifetime afterwards, it'll always be you. When
            we're old, you're the reason I will know that I lived my life right.
          </p>
          <p className="name">Love, Jaden</p>
        </div>
      </div>
    </div>
  );
}
