import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sam from "./pages/Sam";
import Protect from "./pages/Protect";
import { useState } from "react";
import Sam2 from "./pages/Sam2";
import Sam3 from "./pages/Sam3";
import Dashboard from "./pages/Dashboard";
import Card from "./pages/Card";
import { Gallery } from "./pages/Gallery";

function App() {
  const [password, setPassword] = useState("");

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              password === "jaden loves samantha more" ? (
                <Sam password={password} />
              ) : (
                <Protect setPassword={setPassword} />
              )
            }
          ></Route>
          <Route
            path="/jadenlovessamanthalots"
            element={
              password === "jaden loves samantha more" ? (
                <Sam2 password={password} />
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
          <Route
            path="/jadenlovessamanthamorethanlots"
            element={
              password === "jaden loves samantha more" ? (
                <Sam3 password={password} />
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
          <Route
            path="/jadenlovessamanthamost"
            element={
              password === "jaden loves samantha more" ? (
                <Dashboard password={password} />
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
          <Route
            path="/jadenlovessamanthamostest"
            element={
              password === "jaden loves samantha more" ? (
                <Card password={password} />
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
          <Route
            path="/jadenlovessamanthamorethanmostest"
            element={
              password === "jaden loves samantha more" ? (
                <Gallery password={password} />
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
