import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const descriptors = [
  "silly",
  "intelligent",
  "kind",
  "beautiful",
  "curious",
  "talented",
  "hardworking",
  "tired",
  "determined",
  "driven",
  "loving",
  "caring",
  "thoughtful",
  "perfect",
];

const delays = [
  500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 2000,
]; // delays for each message

export default function Sam2() {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    if (index < descriptors.length) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setIndex((prevIndex) => prevIndex + 1);
          if (descriptors[index] !== "perfect") {
            setFade(true);
          }
        }, 500); // 0.5 second fade out
      }, delays[index] + 500); // delay for current message + fade out time

      return () => clearInterval(interval);
    }
  }, [index]);

  return (
    <div className="font-sans font-bold flex items-center h-screen">
      <div className="text-9xl text-center m-auto">
        I never knew someone could be so{" "}
        {index < descriptors.length ? (
          <span
            style={{ opacity: fade ? 1 : 0, transition: "opacity 0.5s" }}
            dangerouslySetInnerHTML={{ __html: descriptors[index] }}
          ></span>
        ) : (
          <Navigate to="/jadenlovessamanthamorethanlots" />
        )}
      </div>
    </div>
  );
}
