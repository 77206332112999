import { Link } from "react-router-dom";

export function Gallery() {
  return (
    <div>
      <Link to="/jadenlovessamanthamost">
        <button className="fixed top-0 left-0 p-4 hover:text-green-400">
          {"<"}
        </button>
      </Link>

      <h1 className="z-50 text-4xl font-bold p-4">
        Some fun pictures of you that I love!{" "}
        <span className="text-sm text-gray-500">(Nothing too crazy)</span>
      </h1>
      <div className="w-1/2 m-auto">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
          <div className="grid gap-4">
            <div>
              <img
                onClick={() => alert("Little cutie sleepy girlfriend!")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="bed.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() => alert("My baby with fun mirror selfie! <3")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="mirror.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  alert("Yay! Prom poster :) Your smile is adorable")
                }
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="poster.jpeg"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                onClick={() => alert("Prom night! You look so beautiful!")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="prom.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() => alert("Rockstar! You're so cool B)")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="rockstar.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  alert(
                    "Sunburnt! You're so cute! Though still make sure to put on sunscreen :)"
                  )
                }
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="sunburn.jpeg"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                onClick={() => alert("Yay thumbs up! So adorable")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="thumbs.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() => alert("Us! You're so cute and cuddly!")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="us.jpeg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() => alert("Little cutie face and fun fist lol")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="face.jpeg"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                onClick={() =>
                  alert("This is my favourite face from you!!! Suspicious!")
                }
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="cute.jpeg"
                alt="gallery-photo"
              />
            </div>

            <div>
              <img
                onClick={() => alert("Thank you for the hearts darling <3")}
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="heart.jpg"
                alt="gallery-photo"
              />
            </div>
            <div>
              <img
                onClick={() =>
                  alert("You're so fit AND you've got a cool fit!")
                }
                className="h-auto max-w-full rounded-lg object-cover object-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                src="fit.JPEG"
                alt="gallery-photo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
