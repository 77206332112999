export default function Protect({ setPassword }) {
  return (
    <main className="h-screen w-screen flex items-center justify-center">
      <h1 className="text-4xl text-pink-400 p-3">
        This is the truest statement in the universe:
      </h1>
      <input
        className="border-none outline-none p-3 bg-pink-400  placeholder:text-pink-800 text-white rounded-md text-4xl w-[800px]"
        onChange={(e) => setPassword(e.target.value)}
      ></input>
    </main>
  );
}
