import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const messages = [
  "Dear <span style='color:#77dd77;'>Samantha Adams</span>,",
  "Happy <em>18th</em> birthday!",
  "You're the <span style='color:#77dd77;'>best partner</span> I could ever wish for.",
  "You're the <span style='color:#77dd77;'>greatest mind</span> to talk with.",
  "You're also the <span style='color:#77dd77;'>silliest mind</span> to talk with.",
  "You're the <span style='color:#77dd77;'>most supportive fan</span> I could have.",
  "You're the <span style='color:#77dd77;'>most understanding girlfriend</span> I could ask for.",
  "You're the <span style='color:#77dd77;'>best role model</span> I could look up to.",
  "You're the <span style='color:#77dd77;'>most inspirational person</span> to me.",
];

const delays = [2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000]; // delays for each message

export default function Sam() {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    if (index < messages.length) {
      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setIndex((prevIndex) => prevIndex + 1);
          setFade(true);
        }, 500); // 0.5 second fade out
      }, delays[index] + 500); // delay for current message + fade out time

      return () => clearInterval(interval);
    }
  }, [index]);

  return (
    <div className="font-sans font-bold flex items-center h-screen">
      {index < messages.length ? (
        <div
          className="text-9xl text-center m-auto"
          style={{ opacity: fade ? 1 : 0, transition: "opacity 0.5s" }}
          dangerouslySetInnerHTML={{ __html: messages[index] }}
        ></div>
      ) : (
        <Navigate to="/jadenlovessamanthalots" />
      )}
    </div>
  );
}
