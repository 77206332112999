import React from "react";
import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <main>
      <h1 className="text-5xl font-extrabold text-green-300 p-3">
        Samantha Adams
      </h1>
      <div className="flex w-full justify-between p-3 gap-4">
        <Link to="/jadenlovessamanthamostest">
          <button className="p-4 bg-green-300 rounded-md hover:bg-green-400 active:bg-green-300 shadow-md text-white text-5xl font-bold">
            Open Card
          </button>
        </Link>
        <Link to="/jadenlovessamanthamorethanmostest">
          <button className="p-4 bg-green-300 rounded-md hover:bg-green-400 active:bg-green-300 shadow-md text-white text-5xl font-bold">
            Open Gallery
          </button>
        </Link>
      </div>
    </main>
  );
}
